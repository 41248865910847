var completedAvsCheck = false;

// PCI-38 Handle mobile safari bugs regarding iframe input elements and blur events.
var activeElementList = [];
var isMobileSafariBrowser = isIos() && (typeof isNativeIos != 'undefined' ? !isNativeIos : true);
var updateTotalForPayPal = null;
var paypalAmount = null;



function initNewCreditCardHostedFields() {
    $("#existing-cvv").html("");
    var $cardNumberContainer = $('.cc-container');
    if ($("#card-number").html() == '') {

        braintree.client.create(braintreeOptions, function (clientErr, clientInstance) {
            if (clientErr) {
                return;
            }

            braintree.hostedFields.create({
                id: "checkoutForm",
                client: clientInstance,
                styles: {
                    "input": {
                        "color": "#333",
                        "font-family": "Roboto, sans-serif",
                        "font-size": ".9em",
                        "font-weight": "300"
                    },
                    ":focus": {
                        "color": "#333333"
                    },
                    ".invalid": {
                        "color": "#EC0000"
                    }
                },
                fields: {
                    number: {
                        selector: "#card-number",
                        placeholder: "Card Card Number : Required"
                    },
                    cvv: {
                        selector: "#cvv",
                        placeholder: "CVV : Required"
                    },
                    expirationMonth: {
                        selector: "#expiration-month",
                        placeholder: "MM : required"
                    },
                    expirationYear: {
                        selector: "#expiration-year",
                        placeholder: "YY : Required"
                    }
                }

            }, function (hostedFieldsErr, hostedFieldsInstance) {
                if (hostedFieldsErr) {
                    handleBrainTreeError(hostedFieldsErr);
                    return;
                }

                hostedFieldsInstance.on('cardTypeChange', function (event) {
                    $cardNumberContainer.removeClass('visa master-card discover american-express');
                    if (event.cards.length === 1) {
                        $cardNumberContainer.addClass(event.cards[0].type);
                    } else {
                        $cardNumberContainer.removeClass('visa master-card discover american-express');
                    }
                });

                hostedFieldsInstance.on('focus', function (event) {
                    if (isMobileSafariBrowser && activeElementList.indexOf(document.activeElement) === -1) {
                        activeElementList.push(document.activeElement);
                    }
                });

                $('#btOrderSubmit').click(function (event) {
                    event.preventDefault();
                    hostedFieldsInstance.tokenize(function (tokenizeErr, obj) {
                        if (tokenizeErr) {
                            handleBrainTreeError(tokenizeErr);
                            return;
                        }

                        if (completedAvsCheck) {
                            submitBTOrder(obj.nonce);
                        } else {
                            verifyCreditCardBT(obj.nonce);
                        }
                    });
                });
            });
        });

        if (isMobileSafariBrowser) {
            $('#checkoutForm').off('touchstart').on('touchstart', function (event) {
                _.each(activeElementList, function (element) {
                    element.blur();
                });
            });
        }
    }
}

function handleBrainTreeError(err) {
    resetSubmitButton();

    if (err.code === 'PAYPAL_POPUP_CLOSED') {
        return;
    } else if (err.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
        logAndAlert("Please enter a payment method");
    } else if (err.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
        logAndAlert("Some payment method input fields are invalid.");
    } else if (err.message) {
        logAndAlert(err.message);
    }
}

function logAndAlert(message) {
    log(message);
    alert(message);
}

function log(message) {
    try {
        var logObject = {
            "message": message,
            "browser.uri": encodeURIComponent(window.location.pathname + window.location.search)
        };

        utils.sendInHouseLog(logObject);
    } catch (e) {
    }
}



function clearHostedFields() {
    $("#cvv").html("");
    $("#card-number").html("");
    $("#expiration-month").html("");
    $("#expiration-year").html("");
    $("#existing-cvv").html("");
}

function initExistingCreditCardHostedFields() {
    $("#cvv").html("");
    $("#card-number").html("");
    $("#expiration-month").html("");
    $("#expiration-year").html("");
    if ($("#existing-cvv").html() == '') {
        braintree.client.create(braintreeOptions, function (clientErr, clientInstance) {
            if (clientErr) {
                return;
            }

            braintree.hostedFields.create({
                id: "checkoutForm",
                client: clientInstance,
                styles: {
                    "input": {
                        "color": "#333",
                        "font-family": "Roboto, sans-serif",
                        "font-size": ".9em",
                        "font-weight": "300"
                    },
                    ":focus": {
                        "color": "#333333"
                    },
                    ".invalid": {
                        "color": "#EC0000"
                    }
                },
                fields: {
                    cvv: {
                        selector: "#existing-cvv",
                        placeholder: "CVV : Required"
                    }
                }

            }, function (hostedFieldsErr, hostedFieldsInstance) {
                if (hostedFieldsErr) {
                    handleBrainTreeError(hostedFieldsErr);
                    return;
                }

                hostedFieldsInstance.on('focus', function (event) {
                    if (isMobileSafariBrowser && activeElementList.indexOf(document.activeElement) === -1) {
                        activeElementList.push(document.activeElement);
                    }
                });

                $('#btOrderSubmit').click(function (event) {
                    event.preventDefault();
                    hostedFieldsInstance.tokenize(function (tokenizeErr, obj) {
                        if (tokenizeErr) {
                            handleBrainTreeError(tokenizeErr);
                            return;
                        }
                        submitOrderExistingCreditCard(obj.nonce);
                    });
                });
            });
        });

        // PCI-38 Handle mobile safari bugs regarding iframe input elements and blur events.
        if (isMobileSafariBrowser) {
            $('#checkoutForm').off('touchstart').on('touchstart', function (event) {
                _.each(activeElementList, function (element) {
                    element.blur();
                });
            });
        }
    }
}

function submitBTOrder(nonce) {
    if (typeof nonce == 'undefined') {
        resetSubmitButton();
        return;
    }
    $('<input>').attr({
        type: 'hidden',
        id: 'bt-nonce',
        name: 'wizard.order.paymentNonce'
    }).appendTo('#checkoutForm');
    $("#bt-nonce").val(nonce);
    $("#checkoutForm").submit();
}

function submitOrderExistingCreditCard(nonce) {

    if (typeof nonce == 'undefined') {
        resetSubmitButton();
        return;
    }
    $('<input>').attr({
        type: 'hidden',
        id: 'cvv-nonce',
        name: 'cvvNonce'
    }).appendTo('#checkoutForm');
    $("#cvv-nonce").val(nonce);
    $("#checkoutForm").submit();
}

function verifyCreditCardBT(nonce) {
    if (typeof nonce == 'undefined') {
        return "Error communicating with payment gateway. Please try again later.";
    }

    var requestData = [
        {
            name: 'verifyCreditCard',
            value: 'true'
        },
        {
            name: 'wizard.order.paymentNonce',
            value: nonce
        }
    ];

    var formData = $("#checkoutForm").serializeArray();
    $.each(formData, function (i, field) {
        if (field.name !== 'ioBlackBox' && field.value.length > 0) {
            requestData.push(field);
        }
    });

    $.ajax({
        url: $("#checkoutForm").attr('action'),
        data: requestData,
        type: 'POST'
    }).done(function (msg) {
        if (msg == 'success') {
            completedAvsCheck = true;
            $("#btOrderSubmit").click();
        } else {
            //TODO: MAKE THIS PRETTY
            logAndAlert(msg);
            resetSubmitButton();
        }
    })
}

function cancelPaypal() {
    var url = window.location.href;
    if (url.indexOf('?') > -1) {
        url += '&cancelPaypal=true'
    } else {
        url += '?cancelPaypal=true'
    }
    window.location.href = url;
}

function onApprovePostProcessing(payload) {
    var isUndefined = _.isUndefined;
    if (isUndefined(payload.details) || (isUndefined(payload.details.billingAddress) && isUndefined(payload.details.shippingAddress))) {
        alert("A billing or shipping address on file with PayPal is required. Please add an address to your PayPal account or choose an alternate payment method.");
        return;
    }

    var postData = formatPaypalData(payload);
    var formAction = $('#checkoutForm').attr('action');

    $('#bt-pp-info').hide();
    $('#pp-error-message').hide();

    $.ajax({
        url: formAction,
        data: postData,
        type: 'POST'
    }).done(function (msg) {
        if (msg === 'success') {
            window.location.href = formAction + '?goToStep=&wizardKey=' + $('input[name="wizardKey"]').val() + '&stepNumber=' + $('input[name="stepNumber"]').val();
        } else {
            logAndAlert(msg);
        }
    });
}

var BT_PAYPAL_INIT = false;

var PAYPAL_INSURANCE_DISCLAIMER =
    'If you select a Pay Later offer to pay for your tickets and add insurance, Allianz Ticket Protection will be charged to the first active payment method from your wallet in the following order: PayPal Balance, bank account, PayPal debit card, PayPal credit card, other debit card, other credit card, or e-check.';

var braintreeClient = null;

function preventMultiplePayPalButtonsOnReRender() {
    let element = document.getElementById('paypal-button');
    while (element?.firstChild) {
        element?.removeChild(element.firstChild);
    }
}

function updatePayLaterWidgetAmount(amount) {
    document.getElementById("pay-later-messaging-order-summary").setAttribute('data-pp-amount', amount);
    document.getElementById("pay-later-messaging-payment-section").setAttribute('data-pp-amount', amount);
}

function renderPayPalButtons(paypalCheckoutInstance, amount) {
    preventMultiplePayPalButtonsOnReRender();

    updatePayLaterWidgetAmount(amount);
    paypalCheckoutInstance.loadPayPalSDK(
        {
            currency: 'USD',
            intent: 'authorize',
            commit: false,
            vault: true,
            'enable-funding': 'paylater',
            components: 'buttons,messages'
        },
        function loadPayPalSDKCallback() {

            var buttonStyle = {
                label: 'pay',
                shape: 'rect',
                layout: 'horizontal',
                tagline: false,
                color: 'gold',
                height: 45
            };
            var createPaymentParameters = {
                flow: 'checkout', // Required
                requestBillingAgreement: true,
                amount: updateTotalForPayPal || amount, // Required
                currency: 'USD', // Required, must match the currency passed in with loadPayPalSDK
                intent: 'authorize',
                displayName: BT_PAYPAL_DISPLAY_NAME || 'Ticket Fulfillment Service',
                enableBillingAddress: true,
                enableShippingAddress: true,
                billingAgreementDetails: {
                    description: PAYPAL_INSURANCE_DISCLAIMER
                }
            };
            paypal
                .Buttons({
                    style: buttonStyle,
                    createOrder() {
                        return paypalCheckoutInstance.createPayment(createPaymentParameters);
                    },
                    onApprove(data) {
                        return paypalCheckoutInstance.tokenizePayment(data)
                            .then(function (payload) {
                                onApprovePostProcessing(payload);
                            });
                    },
                    onError(err) {
                        handleBrainTreeError(err);
                    }
                })
                .render('#paypal-button');

        }
    )
}

function createPayPalClientAndRenderButtons(amount) {
    braintreeClient.then(function (clientInstance) {
        return braintree.paypalCheckout.create({
            client: clientInstance,
        });
    }).then(function (paypalCheckoutInstance) {
        var allowPayPalButtonReRender = document.getElementById("paypal-button");
        if(allowPayPalButtonReRender){
            renderPayPalButtons(paypalCheckoutInstance, amount);
        }
    }).catch(function (err) {
        console.error('Encountered a problem while attempting to load the paypal SDK or rendering paypal buttons', err);
        log(`Encountered a problem while attempting to load the paypal SDK or rendering paypal buttons: ${err}`);
    });
}

function initBTPaypal(amount) {
    paypalAmount = amount;
    if (BT_PAYPAL_INIT) {
        createPayPalClientAndRenderButtons(amount);
        return;

    } else {
        braintreeClient =  braintree.client.create(braintreeOptions);
        createPayPalClientAndRenderButtons(amount);
        BT_PAYPAL_INIT = true;
    }
}

function formatPaypalData(data) {
    data.details = data.details || {};

    var postData = 'applyPaypalPayment=&wizardKey=' + $('input[name="wizardKey"]').val() + '&stepNumber=' + $('input[name="stepNumber"]').val();
    postData += '&wizard.order.billingAddress.firstName=' + data.details.firstName;
    postData += '&wizard.order.billingAddress.lastName=' + data.details.lastName;
    if (!_.isUndefined(data.details.billingAddress)) {
        postData += '&wizard.order.billingAddress.address1=' + data.details.billingAddress.line1;
        if (!_.isUndefined(data.details.billingAddress.line2)) {
            postData += '&wizard.order.billingAddress.address2=' + data.details.billingAddress.line2;
        }
        postData += '&wizard.order.billingAddress.city=' + data.details.billingAddress.city;
        postData += '&wizard.order.billingAddress.state=' + data.details.billingAddress.state;
        postData += '&wizard.order.billingAddress.zip=' + data.details.billingAddress.postalCode;
        postData += '&wizard.order.billingAddress.country=' + data.details.billingAddress.countryCode;
    } else if (!_.isUndefined(data.details.shippingAddress)) {
        postData += '&wizard.order.billingAddress.address1=' + data.details.shippingAddress.line1;
        if (!_.isUndefined(data.details.shippingAddress.line2)) {
            postData += '&wizard.order.billingAddress.address2=' + data.details.shippingAddress.line2;
        }
        postData += '&wizard.order.billingAddress.city=' + data.details.shippingAddress.city;
        postData += '&wizard.order.billingAddress.state=' + data.details.shippingAddress.state;
        postData += '&wizard.order.billingAddress.zip=' + data.details.shippingAddress.postalCode;
        postData += '&wizard.order.billingAddress.country=' + data.details.shippingAddress.countryCode;
    } else {
        postData += '&wizard.order.billingAddress.country=' + data.details.countryCode;
    }

    if (!_.isUndefined(data.details.phone)) {
        postData += '&wizard.order.billingAddress.phone=' + data.details.phone;
        postData += '&wizard.order.shippingAddress.phone=' + data.details.phone;
    }

    if (!_.isUndefined(data.details.shippingAddress)) {
        if (_.isUndefined(data.details.shippingAddress.recipientName)) {
            postData += '&wizard.order.shippingAddress.firstName=' + data.details.firstName;
            postData += '&wizard.order.shippingAddress.lastName=' + data.details.lastName;
        } else {
            postData += '&wizard.order.shippingAddress.firstName=' + data.details.shippingAddress.recipientName.split(' ').slice(0, -1).join(' ');
            postData += '&wizard.order.shippingAddress.lastName=' + data.details.shippingAddress.recipientName.split(' ').slice(-1).join(' ');
        }
        postData += '&wizard.order.shippingAddress.address1=' + data.details.shippingAddress.line1;
        if (!_.isUndefined(data.details.shippingAddress.line2)) {
            postData += '&wizard.order.shippingAddress.address2=' + data.details.shippingAddress.line2;
        }
        postData += '&wizard.order.shippingAddress.city=' + data.details.shippingAddress.city;
        postData += '&wizard.order.shippingAddress.state=' + data.details.shippingAddress.state;
        postData += '&wizard.order.shippingAddress.zip=' + data.details.shippingAddress.postalCode;
        postData += '&wizard.order.shippingAddress.country=' + data.details.shippingAddress.countryCode;
    } else if (!_.isUndefined(data.details.billingAddress)) {
        postData += '&wizard.order.shippingAddress.firstName=' + data.details.firstName;
        postData += '&wizard.order.shippingAddress.lastName=' + data.details.lastName;
        postData += '&wizard.order.shippingAddress.address1=' + data.details.billingAddress.line1;
        if (!_.isUndefined(data.details.billingAddress.line2)) {
            postData += '&wizard.order.shippingAddress.address2=' + data.details.billingAddress.line2;
        }
        postData += '&wizard.order.shippingAddress.city=' + data.details.billingAddress.city;
        postData += '&wizard.order.shippingAddress.state=' + data.details.billingAddress.state;
        postData += '&wizard.order.shippingAddress.zip=' + data.details.billingAddress.postalCode;
        postData += '&wizard.order.shippingAddress.country=' + data.details.billingAddress.countryCode;
    }
    postData += '&wizard.order.shippingAddress.id=0';
    postData += '&wizard.order.paypalTransaction.payerId=' + data.details.payerId;
    postData += '&wizard.order.paypalTransaction.email=' + data.details.email;
    postData += '&wizard.paypalToken=' + data.nonce;
    postData += '&' + $('#checkoutForm [name*="emailAddress"],[name*="repeatedEmail"],[name*="emailSignUp"],[name*="delivery.id"],[name*="agreeToTerms"],[name*="duplicateCheck"]').serialize();

    return postData;
}

function initApplePaySinglePage(applePayDisplayName) {
    braintree.client.create(braintreeOptions, function (clientErr, clientInstance) {
        if (clientErr) {
            return;
        }

        braintree.applePay.create({
            client: clientInstance
        }, function (applePayErr, applePayInstance) {
            if (applePayErr) {
                handleBrainTreeError(applePayErr);
                return;
            }

            $('.apple-pay-button-with-text').click(function () {
                var getData = "updateCheckout=&wizardKey=" + $('input[name="wizardKey"]').val() + "&applePayInsuranceRequestAllowed=0";
                var currentWizardData = JSON.parse($.ajax({
                    url: $("#checkoutForm").attr('action'),
                    data: getData,
                    dataType: 'json',
                    async: false,
                    type: 'GET'
                }).responseText);

                var lineItems = [
                    {
                        label: 'Subtotal',
                        amount: currentWizardData.ticketTotalNumeric
                    },
                    {
                        label: 'Service Charge',
                        amount: currentWizardData.serviceTotalNumeric
                    }
                ];

                var requiredShippingContactFields = ['phone'];

                if (currentWizardData.isShippingRequired) {
                    requiredShippingContactFields = ['postalAddress', 'phone'];
                }

                if (currentWizardData.discount) {
                    lineItems.push({
                        label: 'Discount',
                        amount: currentWizardData.discountNumeric * -1
                    });
                }

                if (currentWizardData.giftCardTotal) {
                    lineItems.push({
                        label: 'Gift Card(s)',
                        amount: currentWizardData.giftCardTotalNumeric * -1
                    });
                }

                if (currentWizardData.deliveryFee && currentWizardData.deliveryFeeNumeric) {
                    lineItems.push({
                        label: currentWizardData.deliveryDescription,
                        amount: currentWizardData.deliveryFeeNumeric
                    });
                }

                if (currentWizardData.salesTaxNumeric) {
                    lineItems.push({
                        label: 'Sales Tax',
                        amount: currentWizardData.salesTaxNumeric
                    });
                }

                var total = {
                    label: applePayDisplayName,
                    amount: currentWizardData.totalChargeNumeric
                };

                var paymentRequestObject = {
                    total: total,
                    lineItems: lineItems,
                    requiredBillingContactFields: ['postalAddress', 'name', 'phone'],
                    requiredShippingContactFields: requiredShippingContactFields
                };
                var singlePagePaymentRequest = applePayInstance.createPaymentRequest(paymentRequestObject);

                var apiVersion = ApplePaySession.supportsVersion(2) ? 2 : 1;
                var session = new ApplePaySession(apiVersion, singlePagePaymentRequest);

                if (singlePagePaymentRequest.total.amount <= 0) {
                    alert("Gift cards that cover the entire cost of the order can\'t be used with Apple Pay. Please select another payment type to use this gift card.");
                    return;
                }
                if (!validateCheckoutForm()) {
                    return;
                }

                session.onvalidatemerchant = function (event) {
                    applePayInstance.performValidation({
                        validationURL: event.validationURL,
                        displayName: applePayDisplayName
                    }, function (validationErr, merchantSession) {
                        if (validationErr) {
                            session.abort();
                            handleBrainTreeError(validationErr);
                            return;
                        }
                        session.completeMerchantValidation(merchantSession);
                    })
                };

                session.onpaymentauthorized = function (event) {
                    applePayInstance.tokenize({
                        token: event.payment.token
                    }, function (tokenizeErr, payload) {
                        if (tokenizeErr) {
                            session.completePayment(ApplePaySession.STATUS_FAILURE);
                            handleBrainTreeError();
                            return;
                        }

                        var postData = formatApplePayData(event.payment);
                        $.ajax({
                            url: $("#checkoutForm").attr('action'),
                            data: postData,
                            type: 'POST'
                        }).done(function (msg) {
                            if (msg === 'success') {
                                session.completePayment(ApplePaySession.STATUS_SUCCESS);

                                // Prevent blank address fields from being submitted - they come from Apple Pay instead
                                $("#billingAddressSection").remove();
                                $("#shippingAddressSection").remove();

                                var billingState, billingCountry, shippingState;
                                var billingAddress = event.payment.billingContact;
                                var shippingAddress = event.payment.shippingContact;
                                if (!_.isUndefined(billingAddress)) {
                                    billingState = billingAddress.administrativeArea;
                                    shippingState = billingAddress.administrativeArea;
                                    billingCountry = billingAddress.countryCode.toUpperCase();
                                }
                                if (!_.isUndefined(shippingAddress)) {
                                    shippingState = shippingAddress.administrativeArea;
                                    setBillingIsShipping(false);
                                }

                                // There is a Stripes bug that is sending null HTML select values. This is a fix to preserve address state and country on form submit
                                // https://stripesframework.atlassian.net/browse/STS-638
                                $("#checkoutForm")
                                    .append("<input type='hidden' name='wizard.order.billingAddress.state' value='" + billingState + "' />")
                                    .append("<input type='hidden' name='wizard.order.shippingAddress.state' value='" + shippingState + "' />")
                                    .append("<input type='hidden' name='wizard.order.billingAddress.country' value='" + billingCountry + "' />");

                                submitBTOrder(payload.nonce);

                            } else {
                                session.completePayment(ApplePaySession.STATUS_FAILURE);
                                alert(msg);
                            }
                        });
                    });
                };

                session.begin();
            });
        });
    });
}

function initApplePay(options, applePayDisplayName) {
    var paymentRequest;

    braintree.client.create(braintreeOptions, function (clientErr, clientInstance) {
        if (clientErr) {
            return;
        }

        braintree.applePay.create({
            client: clientInstance
        }, function (applePayErr, applePayInstance) {
            if (applePayErr) {
                handleBrainTreeError(applePayErr);
                return;
            }

            paymentRequest = applePayInstance.createPaymentRequest({
                total: {
                    label: applePayDisplayName,
                    amount: options.total
                },
                lineItems: [
                    {
                        label: 'Subtotal',
                        amount: options.subtotal
                    },
                    {
                        label: 'Service Charge',
                        amount: options.serviceCharge
                    }

                ],
                requiredBillingContactFields: ['postalAddress', 'name', 'phone'],
                requiredShippingContactFields: ['phone']
            });

            if (options.deliveryFee && options.deliveryType) {
                paymentRequest.lineItems.push({
                    label: options.deliveryType,
                    amount: options.deliveryFee
                });
            }

            if (options.discount) {
                paymentRequest.lineItems.push({
                    label: 'Discount',
                    amount: options.discount * -1
                })
            }

            if (options.giftCard) {
                paymentRequest.lineItems.push({
                    label: 'Gift Card(s)',
                    amount: options.giftCard * -1
                })
            }


            if (options.salesTax) {
                paymentRequest.lineItems.push({
                    label: 'Sales Tax',
                    amount: options.salesTax
                })
            }

            if (options.isShip) {
                var requiredShippingFields = ['postalAddress', 'phone'];
                paymentRequest.requiredShippingContactFields = requiredShippingFields;
            }

            $('.apple-pay-button-with-text').click(function () {
                if (paymentRequest.total.amount <= 0) {
                    alert("Gift cards that cover the entire cost of the order can\'t be used with Apple Pay. Please go back to the previous step and select another payment type to use this gift card.");
                    return;
                }
                if (!validateCheckoutForm()) {
                    return;
                }

                var apiVersion = ApplePaySession.supportsVersion(2) ? 2 : 1;
                var session = new ApplePaySession(apiVersion, paymentRequest);

                session.onvalidatemerchant = function (event) {
                    applePayInstance.performValidation({
                        validationURL: event.validationURL,
                        displayName: applePayDisplayName
                    }, function (validationErr, merchantSession) {
                        if (validationErr) {
                            session.abort();
                            handleBrainTreeError(validationErr);
                            return;
                        }
                        session.completeMerchantValidation(merchantSession);
                    })
                };

                session.onpaymentauthorized = function (event) {
                    applePayInstance.tokenize({
                        token: event.payment.token
                    }, function (tokenizeErr, payload) {
                        if (tokenizeErr) {
                            session.completePayment(ApplePaySession.STATUS_FAILURE);
                            handleBrainTreeError();
                            return;
                        }

                        var postData = formatApplePayData(event.payment);
                        $.ajax({
                            url: $("#checkoutForm").attr('action'),
                            data: postData,
                            type: 'POST'
                        }).done(function (msg) {
                            if (msg == 'success') {
                                session.completePayment(ApplePaySession.STATUS_SUCCESS);
                                submitBTOrder(payload.nonce);
                                $("#checkoutForm").submit();
                            } else {
                                session.completePayment(ApplePaySession.STATUS_FAILURE);
                                alert(msg);
                            }
                        })
                    })
                };

                session.begin();
            });
        })

    });

    function setPaymentRequest(totalCharge, lineItem) {
        if (!_.isUndefined(totalCharge)) {
            paymentRequest.total.amount = totalCharge;
        }
        if (lineItem) {
            var lineItemFound = false;
            for (var i = 0; i < paymentRequest.lineItems.length; i++) {
                if (paymentRequest.lineItems[i].label === lineItem.label) {
                    paymentRequest.lineItems[i].amount = lineItem.amount;
                    lineItemFound = true;
                }
            }
            if (!lineItemFound) {
                paymentRequest.lineItems.push({
                    label: lineItem.label,
                    amount: lineItem.amount
                })
            }
        }
    }

    return {
        setPaymentRequest: setPaymentRequest
    }
}

function formatApplePayData(data) {
    var postData = "applyApplePayPayment=&wizardKey=" + $('input[name="wizardKey"]').val() + "&stepNumber=" + $('input[name="stepNumber"]').val();
    var billingAddress = data.billingContact || {};
    var shippingAddress = data.shippingContact || {};
    if (!_.isEmpty(billingAddress)) {
        postData += "&wizard.order.billingAddress.firstName=" + billingAddress.givenName;
        postData += "&wizard.order.billingAddress.lastName=" + billingAddress.familyName;
        postData += "&wizard.order.billingAddress.address1=" + billingAddress.addressLines[0];
        if (billingAddress.addressLines.length > 1) {
            postData += "&wizard.order.billingAddress.address2=" + billingAddress.addressLines[1];
        }
        postData += "&wizard.order.billingAddress.city=" + billingAddress.locality;
        postData += "&wizard.order.billingAddress.state=" + billingAddress.administrativeArea;
        postData += "&wizard.order.billingAddress.zip=" + billingAddress.postalCode;
        postData += "&wizard.order.billingAddress.country=" + billingAddress.countryCode.toUpperCase();
    } else if (!_.isEmpty(shippingAddress) && !_.isUndefined(shippingAddress.addressLines)) {
        postData += "&wizard.order.billingAddress.address1=" + shippingAddress.addressLines[0];
        if (shippingAddress.addressLines.length > 1) {
            postData += "&wizard.order.billingAddress.address2=" + shippingAddress.addressLines[1];
        }
        postData += "&wizard.order.billingAddress.city=" + shippingAddress.locality;
        postData += "&wizard.order.billingAddress.state=" + shippingAddress.administrativeArea;
        postData += "&wizard.order.billingAddress.zip=" + shippingAddress.postalCode;
        postData += "&wizard.order.billingAddress.country=" + shippingAddress.countryCode.toUpperCase();
    } else {
        postData += "&wizard.order.billingAddress.country=" + billingAddress.countryCode.toUpperCase();
    }

    if (!_.isEmpty(shippingAddress) && !_.isUndefined(shippingAddress.phoneNumber)) {
        postData += "&wizard.order.billingAddress.phone=" + shippingAddress.phoneNumber;
        postData += "&wizard.order.shippingAddress.phone=" + shippingAddress.phoneNumber;
    }

    if (!_.isEmpty(shippingAddress) && !_.isUndefined(shippingAddress.addressLines)) {
        postData += "&wizard.order.shippingAddress.firstName=" + shippingAddress.givenName;
        postData += "&wizard.order.shippingAddress.lastName=" + shippingAddress.familyName;
        postData += "&wizard.order.shippingAddress.address1=" + shippingAddress.addressLines[0];
        if (shippingAddress.addressLines.length > 1) {
            postData += "&wizard.order.shippingAddress.address2=" + shippingAddress.addressLines[1];
        }
        postData += "&wizard.order.shippingAddress.city=" + shippingAddress.locality;
        postData += "&wizard.order.shippingAddress.state=" + shippingAddress.administrativeArea;
        postData += "&wizard.order.shippingAddress.zip=" + shippingAddress.postalCode;
        postData += "&wizard.order.shippingAddress.country=" + shippingAddress.countryCode.toUpperCase();
    } else if (!_.isEmpty(billingAddress)) {
        postData += "&wizard.order.shippingAddress.firstName=" + billingAddress.givenName;
        postData += "&wizard.order.shippingAddress.lastName=" + billingAddress.familyName;
        postData += "&wizard.order.shippingAddress.address1=" + billingAddress.addressLines[0];
        if (billingAddress.addressLines.length > 1) {
            postData += "&wizard.order.shippingAddress.address2=" + billingAddress.addressLines[1];
        }
        postData += "&wizard.order.shippingAddress.city=" + billingAddress.locality;
        postData += "&wizard.order.shippingAddress.state=" + billingAddress.administrativeArea;
        postData += "&wizard.order.shippingAddress.zip=" + billingAddress.postalCode;
        postData += "&wizard.order.shippingAddress.country=" + billingAddress.countryCode.toUpperCase();
    }
    postData += "&wizard.order.shippingAddress.id=0";
    postData += "&" + $("#checkoutForm [name*='emailAddress'],[name*='repeatedEmail'],[name*='emailSignUp'],[name*='delivery.id'],[name*='agreeToTerms'],[name*='duplicateCheck']").serialize()

    return postData;
}

function enableTfsApplePayIfCompatible(callback) {
    try {

        if (!window.ApplePaySession || !window.ApplePaySession.canMakePayments()) {
            return;
        }

        var iosVersion = utils.getIosVersion();
        if (!utils.isIosVersionApplePayCompatible(iosVersion)) {
            return;
        }

        braintree.client.create(braintreeOptions, function (clientErr, clientInstance) {
            if (clientErr) {
                return;
            }

            braintree.applePay.create({
                client: clientInstance
            }, function (applePayErr, applePayInstance) {
                if (applePayErr) {
                    handleBrainTreeError(applePayErr);
                    return;
                }
                // Show Apple Pay if user has an Apple Pay wallet set up with at least 1 active card
                ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier)
                    .then(function (canMakePayments) {
                        canMakePayments = canMakePayments || !(document.pageScope && document.pageScope.isProduction && location.protocol === 'https')
                        if (canMakePayments) {
                            $('#applePayToggle').show();
                            if (_.isFunction(callback)) {
                                callback();
                            }
                        }
                    })
                    ['catch'](function (e) {
                });
            });
        });
    } catch (e) {
    }
}
